@import '../variables';
@import '../mixins/borders';

.anglo-remittance {
  
  @include media-breakpoint-up(md) {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  &__success-submit {
    background-color: $aatc-green-accept;
    color: white;
    padding: 1rem;
  }

  &__reminders {
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.16);
    min-height: 264px;
    background-color: white;

    padding: 1rem 1.25rem;
    position: relative;

    &__h2 {
      font-size: 20px;
      margin-bottom: 0.5rem;
    }

    &__ul {
      padding-left: 1rem;
    }

    &__p {
      font-size: 14px;
      margin-bottom: 1rem;
    }
  }

  &__open-form {
    background-color: white;
    padding: 0.5rem;
    width: 100%;
    text-align: center;
    border: none;
    border-radius: 5px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.16);
    transition: all 0.3s ease;

    &:hover {
      background-color: $aatc-blue;
      color: white;
    }
    
  }

  &__list {

    &__add {
      background-color: white;
      border: none;
      padding: 1rem;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.16);
      height: 240px;
      width: 100%;
      transition: all 0.3s ease;

      &:hover {
        background-color: $aatc-blue;
        color: white;
      }
    }

    &__item {

      background-color: white;
      // padding: 1rem;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.16);
      // height: 320px;

      &.selected {
        box-shadow: 0 0 5px 2px $aatc-orange;
      }

      &__upper {
        background-color: white;
        padding: 1.5rem 1rem;
        font-size: 20px;
        font-family: $aatc-heading-font;
        position: relative;

        &__close {
          position: absolute;
          bottom: 1rem;
          right: 1rem;
        }
      }

      &__lower {
        background-color: $light-gray-bg;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        padding: 1rem 0.5rem;
        border-top: 1px solid #c0c0c0;
      }

      &__detail {
        margin-top: 0.5rem;

        &__label {
          color: $font-color-accent;
          border-bottom: 1px solid $text-color;
          font-size: 13px;
        }

        &__value {
          // text-transform: capitalize;
          font-size: 18px;
        }
      }

      &__action {
        background-color: $aatc-blue-2;
        border: none;
        color: white;
        height: 48px;
        font-size: 18px;
        text-transform: uppercase;
        transition: all 0.3s ease;

        &:hover {
          background-color: $aatc-blue;
        }
      }
    }
  }

  &__right {
    height: 420px;
    position: sticky;
    top: 92px;
  }
  
}

.dash-remittance {
  position: relative;

  &__pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    &__page {
      border: 1px solid $divider-color;
      background-color: $light-gray-bg;
      color: $light-gray-text-color;

      &.active {
        color: $aatc-blue-light;
      }

      &.arrow {
        background-color: transparent;
        border: none;
        cursor: pointer !important;
      }
    }
  }

  &__filter-row {

    &__filter-btn {
      font-size: 16px;
      color: $aatc-blue-light !important;
      cursor: pointer;
  
      i {
        font-size: 14px;
      }
    }

    &__filters {
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.16);
      min-height: 32px;

      padding: 1rem 1.25rem;
      background-color: white;

      // display: flex;
      // flex-direction: row;
      // align-items: flex-end;
      // justify-content: space-between;

      &__label {
        font-size: 14px;
        margin-bottom: 4px;
      }

      &__date {
        min-width: 340px;
      }
    }
  }
  

  &__grid-container {
    height: 100%;
    width: 100%;
    overflow: hidden;

    &__grid {
      overflow-x: scroll;
    }
  }

}