@import '../../variables';
@import '../../mixins/card';

.view-withdrawal {
  &__data {
    &__label {
      color: $font-color-accent;
      font-size: 16px;
      margin-bottom: 0;
      font-weight: 400;
      border-bottom: 1px solid $divider-color;
    }

    &__value {
      text-transform: capitalize;
      font-weight: 500;
    }
  }

  &__brands {
    &__agent-name {
      font-size: 20px;
      // font-style: italic;
      text-decoration: underline;
    }

    &__grid {
      overflow-x: scroll;

      &__header {
        font-weight: 500;
        text-align: center;
        border: none !important;
      }

      &__row {
        border-top: 1px solid $divider-color;
        display: flex;
        flex-direction: row;
        box-sizing: border-box;
        width: fit-content;

        @include media-breakpoint-up(lg) {
          width: 100%;
        }

        &.last {
          border-bottom: 1px solid $divider-color;
        }

        &.header {
          border: none !important;
        }
      }

      &__col {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        border-left: 1px solid $divider-color;
        text-align: center;
        width: 100%;
        min-width: 128px;
        box-sizing: border-box;

        &.last {
          border-right: 1px solid $divider-color;
        }

        &.no-borders {
          border: none;
        }
      }
    }
  }
}