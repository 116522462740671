@import '../../variables';

.anglo-remittance-report {
  // max-width: 1123px;
  // width: min-content;
  width: 1724px;
  background-color: white;
  padding-bottom: 12rem;

  &__head {
    font-weight: 500;
    font-size: 22px;
  }

  .data-grid__col {
    // border-color: $text-color !important;
    border: 1px solid $text-color !important;
    width: auto;
    // min-width: 160px;

    // min-height: 32px;

    // font-size: 13px;
    font-size: 20px;
    font-weight: 500;
    min-height: 54px;


    &:last-child {
      border-right: 2px solid $text-color !important;
    }
  }

  .data-grid__row {
    // border-color: $text-color !important;
    border: none;
    background-color: white;

    &.page-end {
      padding-bottom: 33px; // 32 + 1 because 1 is the border

      // border-bottom: 1px solid $text-color !important;

      margin-bottom: 156px;
      
    }

    &.page-beginning {
      padding-top: 48px;

      border-bottom: 1px solid $text-color !important;

      margin-top: 200px;
    }

    

    &.header {
      &.data-grid__col {
        background-color: white;
        font-style: italic;
      }
    }
  }

  &__total {
    padding: 0.5rem 0.75rem;
    border: 1px solid $text-color;
    font-size: 36px;
    max-width: 600px;
  }

  .anglo-modal__body {
    height: 800px;
    overflow: scroll;
  }

}