@import '../../variables';
@import '../../mixins/borders';

.anglo-remittance__submit-form {
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.16);
  min-height: 160px;
  background-color: white;

  padding: 1rem 1.25rem;
  position: relative;

  min-height: 264px;

  &__label {
    font-size: 14px;
    margin-bottom: 4px;
  }
}