@import '../variables';
@import '../mixins/borders';

.signature-block {
  text-align: center;
  h4 {
    border-bottom: 1px solid $text-color;
    font-size: 18px;
    padding: 4px;
    margin-bottom: 0;
  }

  .values {
    padding-left: 4px;
    padding-right: 4px;

    p {
      margin: 0;
    }
  }
}