@import '../../variables';
@import '../../mixins/card';

.create-iw-modal {

  .anglo-modal {
    &__body {
      min-height: 320px;
    }
  }
}

.create-pd-modal {

  .anglo-modal  {
    &__body {
      min-height: 320px;
    }
  }

  &__new-location {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      align-items: initial;
      justify-content: flex-end;
    }
  }

  &__new-location-button {
    border: none;
    outline: transparent !important;
    background-color: transparent;
    color: $aatc-blue-light;
    font-size: 12px;
    text-decoration: underline;
    transition: all 0.3s ease;

    &:hover {
      color: $light-gray-text-color;
    }
  }
}