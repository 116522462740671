@import '../variables';
@import '../mixins/borders';

.dash-updates {
  position: relative;

  &__type-row {
    &__button {
      border: 1px solid $light-gray-text-color;
      background-color: white;
      outline: none;
      padding: 0.5rem;

      &.active {
        color: white;
        background-color: $text-color;
      }

      &.first {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }

      &.last {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        border-left: none;
      }
    }
  }

  &__filter-row {

    &__filter-btn {
      font-size: 16px;
      color: $aatc-blue-light !important;
      cursor: pointer;
  
      i {
        font-size: 14px;
      }
    }

    &__filters {
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.16);
      min-height: 32px;

      padding: 1rem 1.25rem;
    }
  }
  

  &__grid-container {
    height: 100%;
    width: 100%;
    overflow: hidden;

    &__grid {
      overflow-x: scroll;
    }
  }

}