@import '../variables';
@import '../mixins/borders';

.anglo {
  background-color: $light-gray-bg;
  min-height: 100vh;

  &__content {
    display: flex;
    flex-direction: row;
    width: 100%;
    min-height: 100%;

    &__routes {
      width: 100%;
      min-height: 100%;
      flex: 1;

      @include media-breakpoint-up(lg) {
        width: calc(100% - 260px);
      }
    }

  }

  &__dashboard {
    padding-bottom: 1rem;

    @include media-breakpoint-up(lg) {
      padding-top: 1rem;
      padding-bottom: 1rem;
      padding-left: 1.25rem;
      padding-right: 1.25rem;
    }

    &__faq {
      font-size: 13px;
      color: $text-color;

      i {
        font-size: 10px;
      }
    }

    &__tab {
      padding-left: 1rem;
      padding-right: 1rem;
      padding-top: 4px;
      padding-bottom: 4px;

      background-color: white;
      color: $font-color-accent;
      border-color: $font-color-accent;
      box-shadow: none !important;
      font-size: 16px;

      // height: 33px;

      &:first-child {
        @include anglo-border-radius(left, 18px);
      }

      &:last-child {
        @include anglo-border-radius(right, 18px);
      }

      &.active {
        background-color: $font-color-accent;
        color: white;
      }

    }

    &__tips {
      padding: 0.75rem;
      font-size: 13px;
      font-weight: 500;

      border: 1px solid $divider-color;

      span {
        font-weight: normal;
      }

      @include media-breakpoint-up(lg) {
        padding: 1rem 1.25rem;
        font-size: 19px;

        span {
          font-size: 18px;
        }
      }

    }
  }
    
}