@import '../variables';
@import '../utils';

.anglo__content__sidenav-padding {
  padding-right: 260px;
}

.anglo__sidenav-container {
  height: calc(100vh - 76px);
  width: 100vw;
  position: fixed;
  z-index: 4;
  background-color: white;

  transition: all 0.3s ease;
  left: -100%;
  // display: none;
  opacity: 0;
  overflow-y: scroll;

  &.visible {
    // display: block;
    opacity: 1;
    left: 0;
  }

  @include media-breakpoint-up(xl) {
    opacity: 1;
    left: auto;
    width: 260px;
    position: fixed;
    display: block;
    border-right: 1px solid $divider-color;

    transition: none;
  }

  &::-webkit-scrollbar {
    display: none !important;
  }
}

.anglo__sidenav {

  &__user {
    height: 160px;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__img {
      border-radius: 50%;
      width: 60px;
      height: 60px;
      background-color: $aatc-red;
    }

    &__name {
      font-size: 15px;
      font-weight: 600;
      margin-top: 0.75rem;
      margin-bottom: 0;
    }

    &__type {
      font-size: 14px;
      font-weight: normal;
      color: font-color-accent;
      margin-top: -4px;
      margin-bottom: 0;
    }
  }

  &__routes-list {
    list-style: none;
    padding: 0;
    margin: 0;

    &__item {
      height: 59px;
      width: 100%;
      border-top: 1px solid $divider-color;
      background-color: white;
      display: flex;
      justify-content: start;
      align-items: center;
      box-sizing: border-box;

      font-size: 16px;

      cursor: pointer;

      a {
        color: $body-color;
        position: relative;
        margin-left: 36%; 
        // 30% to give it a 1/3 spacing on the right
        // in order to keep the alignment of the icons

        @include media-breakpoint-up(md) {
          margin-left: 45%; 
        }

        @include media-breakpoint-up(lg) {
          margin-left: 45%; 
        }

        @include media-breakpoint-up(xl) {
          margin-left: 33%; 
        }

        i {
          margin-right: 8px;
        }
      }

      &.disabled {
        cursor: default;
        // background-color: $divider-color;
        opacity: 0.6;
      }

      &.active {
        box-shadow: inset -5px -0 1px 0px $aatc-orange;
        background-color: $light-gray-bg;
      }
      
      &.hidden {
        display: none;
      }

      &:last-child {
        border-bottom: 1px solid $divider-color;
      }

      &__notif {
        border-radius: 50%;
        width: 17px;
        height: 17px;
        display: block;

        background-color: $aatc-blue-light;
        color: white;

        font-size: 12px;
        font-weight: 500;
        text-align: center;
        padding-bottom: 0px;
        position: absolute;
        right: -18px;
        top: -4px;
      }
    }
  }

}