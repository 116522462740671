@import '../variables';

.anglo-toast__container {
  position: fixed;
  z-index: 901;

  bottom: 12px;
  left: 12px;
}

.anglo-toast {
  opacity: 1;
  // padding: 1rem;
  border: 1px solid $divider-color;
  margin: 1rem;
  cursor: pointer;

  &:hover {
    border-color: black;
  }

  &.toast-error {
    background-color: $aatc-red-declined;
    border: none;

    .toast-header {
      color: white;
      font-weight: bold;
      background-color: $aatc-red-declined;
    }
  }
}

.anglo-toast__title {
  font-weight: 900;
}

.anglo-toast__message {
  margin: 0;
  background-color: $light-gray-bg;
}