@import '../../variables';
@import '../../mixins/borders';
@import '../../mixins/widget-collapsable';
@import '../../mixins/widget-generic';

.hi-broker-leaderboard-widget {
  @include widgetCollapsable(calc(368px - 20px - 16px));

  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.16);
  max-height: 382px; // to help align with the other widgets
  
  overflow: hidden;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  
  padding: 1rem 1.25rem;
  position: relative;

  @include media-breakpoint-up(md) {
    min-height: 160px;
  }

  @include media-breakpoint-up(xl) {
    max-height: 368px;
  }

  @include widgetGeneric();

  &__broker-list {
    overflow: scroll;
    overflow-x: hidden;
    width: 100%;
  }

  &__broker {

    display: flex;
    flex-direction: row;

    &__img {
      border-radius: 50%;
      min-width: 55px;
      width: 55px;
      height: 55px;
      background-color: $shadow-color;
      margin-right: 0.5rem;

      @include media-breakpoint-up(md) {
        margin-right: 1rem;
      }
    }

    &__details {
      display: flex;
      flex-direction: column;
      justify-content: center;

      width: 100%;
    }

    &__name {
      font-size: 15px;
      font-weight: 500;
      margin-bottom: 0;
    }

    &__counts {
      margin-bottom: 0;

      &__inquiry {
        font-size: 13px;
        font-weight: normal;
        margin-bottom: 0;
        margin-right: 0.25rem;

        @include media-breakpoint-up(md) {
          margin-right: 0.5rem;
          font-size: 14px;
        }
      }
  
      &__listings {
        font-size: 13px;
        font-weight: 400;
        margin-bottom: 0;
        color: $font-color-accent;

        @include media-breakpoint-up(md) {
          font-size: 14px;
        }
      }
    }

    &__placement {
      display: flex;
      flex-direction: column;
      justify-content: center;

      &__badge {
        width: 34px;
        height: 34px;
        border-radius: 50%;
        color: white;
        background-color: $aatc-blue;

        font-size: 24px;

        display: flex;
        align-items: center;
        justify-content: center;

        &.offline {
          background-color: $aatc-red;
        }
      }
    }

  }

}