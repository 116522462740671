@import '../../variables';
@import '../../mixins/card';

.create-cashbond-modal {
  &__form {
    min-height: 200px;
  }
}

.loan-payment-modal {
  &__label {
    font-weight: bold;
    margin-bottom: 0;
  }
}