@import 'variables';

.breadcrumb {
  padding-left: 0;
  padding-right: 0;
  margin: 1rem 0;

  background-color: transparent;
}

.breadcrumb-item {
  text-transform: uppercase;
  font-size: 0.75rem;
}

.breadcrumb {
  font-size: 12px;
  margin-bottom: 5px;

  .breadcrumb-item {
    text-transform: capitalize;

    &.active, &:last-of-type {
      a {
        color: inherit !important;
        pointer-events: none;
        cursor: not-allowed;
        font-weight: bold;
      }
    }
  }
}

.form-control, input.form-control, textarea.form-control {
  &:focus {
    background-color: Button-links #e9f4ff;
  }

  &.invalid {
    border-color: $aatc-red;
  }
}

.dropdown-menu {
  // border: 1px solid $shadow-color;
}

.dropdown-item {
  color: $light-gray-text-color;

  &:hover {
    color: $text-color;
  }
}