@import '../../variables';
@import '../../mixins/card';

.create-withdrawal-modal {
  &__body {
    background-color: $light-gray-bg;
  }

  &__list {
    &__group {
      padding: 1.5rem 0.5rem;
      background-color: white;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.16);
    }
  }

  &__form {
    &__brand-card {
      background-color: white;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.16);

      &.editing {
        border: 2px solid $aatc-orange;
      }

      &__name {
        font-size: 20px;
        font-family: $aatc-heading-font;
      }

      &__label {
        color: $font-color-accent;
        border-bottom: 1px solid $text-color;
        font-size: 13px;
      }

      &__value {
        font-size: 18px;
      }
      
    }

    &__brand {
      background-color: white;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.16);

      margin: 0;
      margin-top: 1.5rem;
    }
  }
}