@import '../../variables';
@import '../../mixins/borders';

.anglo-data-display-widget {
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.16);
  min-height: 160px;

  padding: 1rem 1.25rem;
  position: relative;

  @include anglo-border-radius-smoothen(all);

  &__coming-soon {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(249, 249, 249, 0.9);
    z-index: 3;

    display: flex;
    align-items: center;
    justify-content: center;

    &__h4 {
      font-size: 19px;
      font-weight: 500;
      text-transform: uppercase;
    }
  }

  &__name {
    font-size: 15px;
    font-weight: 500;
  }

  &__change-amount {
    font-size: 13px;

    &.green {
      color: $aatc-leaf-green;
    }

    &.red {
      color: red;
    }
  }

  &__external-link {
    font-size: 14px;
    color: blue;
    display: flex;
    flex-direction: row;
    align-items: center;

    i {
      margin-left: 4px;
      font-size: 9px;
      font-weight: 500;
    }
  }

  &__data {

    text-align: center;

    &__amount {
      color: $aatc-jade;
      font-size: 32px;
      margin-bottom: 0;

      // @include media-breakpoint-up(xl) {
      //   font-size: 38px;
      // }
    }

    &__label {
      font-size: 13px;
      margin-top: -2px;
      margin-bottom: 0;

      // @include media-breakpoint-up(xl) {
      //   font-size: 13px;
      // }
    }

    &.first {
      border-left: none !important;
      border-top: none !important;
    }

    &:last-child {
      border-top: 1px solid $divider-color;

      // @include media-breakpoint-up(xl) {
      //   border-top: none;
      //   border-left: 1px solid $divider-color;
      // }
    }
  }
}