@import '../variables';

.anglo-navbar {
  display: block;
  width: 100%;

  &__contents {
    height: 76px;
    width: 100%;
    background-color: white;
    position: fixed;
    z-index: 10;
    top: 0;

    box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.11);
  }

  &__logo {
    width: 100%;
    img {
      object-fit: cover;
      width: 100px;
    }
  }

  &__padding {
    padding-bottom: 76px;
  }
}