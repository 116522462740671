@import '../variables';
@import '../mixins/card';

.anglo-modal-container {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 98;

  top: 0;
  left: 0;

  // Will be using a flex technique for now.
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @include media-breakpoint-down(sm) {
    justify-content: flex-start;
    align-items: flex-start;

    width: 100vw;

    .anglo-modal {
      height: 100vh;
    }
  }

  &.full-page {
    justify-content: flex-start;
    align-items: flex-start;

    .anglo-modal {
      height: 100vh;
      overflow: scroll;
    }
  }

  &.scrollable {
    @include media-breakpoint-up(lg) {
      align-items: flex-start;
      padding-top: 3rem;
      padding-bottom: 3rem;
      overflow-y: scroll;
    }
  }

  background-color: rgba(51, 51, 51, 0.3);
}

.anglo-modal {
  position: relative;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.16);
  // padding: 1.25rem; // 1 rem = 16px; 20px = 1.25rem
  padding: 0;
  background-color: white;
  width: 100%;
  
  // height: 68.5%;

  // @include media-breakpoint-down(sm) {
  //   height: 100vh;
  //   width: 100vw;
  //   top: 0;
  //   left: 0;
  // }

  &__head {
    padding: 1.25rem;
    margin: 0;
    border-bottom: 1px solid $divider-color;
    background-color: $aatc-blue;
    color: white;

    h3 {
      font-size: 22px;
      font-weight: 500;
    }

    &__small {
      font-size: 15px;
    }
  }

  &__content {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  &__body {
    padding: 1.25rem;
    overflow: scroll;
    height: 100%;
    max-height: 80vh;
  }

  &__actions {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}

.anglo-request-edit-modal {
  &__field {
    @include card();
    padding: 1rem 1.25rem;
    margin-bottom: 1.5rem;

    &__head {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      h4 {
        font-size: 18px;
      }
    }
  }

  &__label {
    font-size: 18px;
  }

  &__reason {
    resize: none;
    min-height: 140px;
  }

  &__error {
    color: $aatc-red;
    font-size: 15px;
  }
}

.view-update-detail-modal {
  &__status-reason {
    // border: 1px solid $divider-color;
    &__label {
      font-size: 18px;
      width: 160px;
    }

    &__value {
      font-size: 15px;
      margin-left: 0;
    }
  }

  &__label {
    font-size: 18px;
    font-weight: 400;
  }

  &__value {
    margin-top: -8px;
    font-size: 15px;
    font-style: italic;
  }

  &__fields-container {
    background-color: $light-gray-bg;
    padding: 1.25rem 0;

    &__header {
      font-size: 18px;
      color: $aatc-red;
      text-decoration: underline;
    }
  }

  &__action {
    color: white;
    min-width: 100px;

    &.approve {
      background-color: $aatc-jade;
      border-color: $aatc-jade;
    }

    &.reject {
      background-color: $aatc-orange;
      border-color: $aatc-orange;
    }
  }
}