@import '../variables';

@mixin anglo-top-orange {
  border-top: 5px solid orange; 
}

@mixin anglo-border-radius-smoothen($side) {
  @if $side == 'all' {
    border-radius: $border-radius;
  }

  @if $side == 'top' {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }

  @if $side == 'bottom' {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }

  @if $side == 'left' {
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
  }

  @if $side == 'right' {
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}

@mixin anglo-border-radius($side, $amount) {
  @if $side == 'all' {
    border-radius: $amount;
  }

  @if $side == 'top' {
    border-top-left-radius: $amount;
    border-top-right-radius: $amount;

    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  @if $side == 'bottom' {
    border-bottom-left-radius: $amount;
    border-bottom-right-radius: $amount;

    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  @if $side == 'left' {
    border-top-left-radius: $amount;
    border-bottom-left-radius: $amount;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  @if $side == 'right' {
    border-top-right-radius: $amount;
    border-bottom-right-radius: $amount;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}