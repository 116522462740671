@import '../../variables';
@import '../../mixins/card';

.vehicle-history-item {
  @include card();

  &__head {
    font-size: 20px;
    font-weight: bold;
    padding: 1rem 1.25rem;
    padding-bottom: 0.5rem;

    &__small {
      font-size: 14px;
      text-transform: uppercase;
      margin-left: 4px;
      font-weight: 400;
    }
  }

  &__body {

    padding: 1rem 1.25rem;
    border-top: 1px solid $divider-color;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &__detail {
      width: 50%;
      margin-right: 0;
      text-align: center;

      &__title {
        font-size: 13px;
        color: $font-color-accent;
        margin-bottom: 0;
      }
    }
  }
}