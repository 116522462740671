@import '../../variables';
@import '../../mixins/card';

.update-card {
  @include card();

  &__head {
    border-bottom: 1px solid $divider-color;
    padding: 1rem 1.25rem;

    &.approved {
      background-color: $aatc-green-accept;
      color: white;
    }

    &.pending {
      background-color: $aatc-yellow-pending;
      color: $text-color;
    }

    &.denied {
      background-color: $aatc-red-declined;
      color: white;
    }
  }

  &__content {
    padding: 1rem 1.25rem;
  }

  &__body {
    font-size: 16px;

    &__label {
      color: $font-color-accent;
    }

    &__value {
      text-align: right;

      &__reason {
        float: right;
        overflow: hidden;
        width: 180px;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  &__actions {
    padding: 1rem 1.25rem;
  }

}