@import '../../variables';
@import '../../mixins/card';

.vehicle-card {
  @include card();


  &.file {
    min-height: 340px;
  }

  &__head {
    border-bottom: 1px solid $divider-color;
    padding: 1rem 1.25rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &.approved {
      background-color: $aatc-green-accept;
      color: white;
    }

    &.pending {
      background-color: $aatc-yellow-pending;
      color: $text-color;
    }

    &.denied {
      background-color: $aatc-red-declined;
      color: white;
    }
  }

  &__body {
    font-size: 15px;
    padding: 1rem 1.25rem;

    &__assigned {
      border-top: 4px solid $aatc-orange;

      padding: 1rem 1.25rem;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      font-size: 16px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    &__label {
      color: $font-color-accent;
    }

    &__value {
      text-transform: capitalize;
    }
  }

  &__actions {
    padding: 1rem 1.25rem;
    padding-top: 0;
  }

}