@import '../variables';

.data-grid {
  display: flex;
  flex-direction: column;

  background-color: white;

  &__row {
    display: flex;
    flex-direction: row;

    border-top: 1px solid $grid-border-color;

    transition: all 0.3s ease;

    &.active {
      .data-grid__col {
        background-color: $aatc-red;
        color: white;
      }
    }

    &.header{

      .data-grid__col {
        font-weight: 500;
        border-bottom: 3px solid $aatc-blue;
      }

      &:hover {
        background-color: initial;
        color: initial;

        .data-grid__col {
          background-color: initial;
          color: initial;
        }
      }
    }

    &:nth-child(even) {
      background-color: $light-gray-bg;
    }

    &:last-child {
      border-bottom: 1px solid $grid-border-color;
    }

    &:hover {
      .data-grid__col {
        background-color: $aatc-red;
        color: white;
      }
    }
  }

  &__col {
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: start;

    border-left: 1px solid $grid-border-color;
    padding: 0.25rem 0.75rem;
    width: 100%;
    min-width: 180px;

    min-height: 48px;

    font-size: 15px;
    font-family: $aatc-body-font;

    &:last-child {
      border-right: 1px solid $grid-border-color;
    }

    &.col-long {
      min-width: 280px;
    }

    &.col-short {
      min-width: 100px;
      width: 100%;
    }

  }

}
