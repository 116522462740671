@import '../variables';

@mixin widgetCollapsable($height) {
  .widget-collapsable {

    &__toggle-row {
      i {
        &.visible {
          transform: rotate(180deg);
        }
      }
    }

    &__body {
      display: none;
      overflow: hidden;
      flex-direction: column;

      &.visible {
        display: flex;
        flex-direction: column;
        height: $height;
      }

      @include media-breakpoint-up(md) {
        display: flex;

        &.visible {
          height: auto;
          display: flex;
          flex-direction: auto;
        }
      }
    }
  }
}