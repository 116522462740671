 @import "variables";

.scroll-lock {
    height: 100vh;
    overflow: hidden;
}

a {
  text-decoration: none;
  // color: $hoppler-blue;
}

// The scrollbar
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background: rgba(255,0,0,0.0);
}

/* Track */
::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgba(255,0,0,0.0);
}

::-webkit-scrollbar-track-piece {
    background: rgba(255,0,0,0.0);
}

/* Handle */
::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgba(180,180,180,0.8);
    // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
	background: rgba(130,130,130,0.4);
}