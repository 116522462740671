@import '../variables';

.anglo-login {
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: start;

  position: fixed;

  width: 100%;
  height: 100%;

  background-color: $aatc-blue;

  &__logo {
    img {
      width: 100px;
      margin: 0 auto;
      display: block;
      object-fit: cover;
    }
  }

  &__login-card {
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.16);
    min-height: 320px;
    width: 300px;

    padding: 1rem 1.25rem;
    padding-bottom: 1.5rem;
    position: relative;

    display: flex;
    flex-direction: column;

    background-color: white;
    border-bottom: $aatc-red;

    

    @include media-breakpoint-up(md) {
      width: 480px;
      margin-top: 5rem;
    }

    &__h1 {
      color: $aatc-blue;
      text-align: center;
      font-family: $aatc-heading-font;
      font-weight: normal;
    }

    &__forgot {
      font-size: 14px;
    }
  }

}