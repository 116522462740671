@import './utils';

$aatc-red: #d7423e;
$aatc-blue: #18537f;
$aatc-orange: #f25b15;
$aatc-blue-light: #247cbf;

$aatc-blue-2: #1F68A3;
$aatc-blue-dark: #215B8A;

$aatc-jade: #1b9b85;
$aatc-leaf-green: #50b83c;

$aatc-green-accept: #0C8A38;
$aatc-yellow-pending: #D9DD56;
$aatc-red-declined: #d7423e;

$shadow-color: #888888;

$text-color: #333;
$font-color-accent: #999999;
$lighter-text-color: #4a4a4a;
$light-gray-text-color: #8b8b8b;

$divider-color: #d4d1d1;
$grid-border-color: rgba(220, 220, 220, 0.5);

// Bootstrap overrides
$body-color: #333;
$light-gray-bg: #f9f9f9;

$theme-colors: (
  'primary': $aatc-blue,
  'secondary': $aatc-orange,
  'info': $aatc-leaf-green
);


$border-radius: 3px;

// FONTS!
$aatc-body-font: 'Roboto', sans-serif;
$aatc-heading-font: 'Quicksand', sans-serif;