@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap');
// @import '../../node_modules/';


@import './variables';

// Font Awesome
// $fa-font-path: '~../../node_modules/@fortawesome/fontawesome-free/webfonts';
$fa-font-path: '../../node_modules/@fortawesome/fontawesome-free/webfonts';

@import '~@fortawesome/fontawesome-free/scss/fontawesome';
// We need solid and brands
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';

* {
  font-family: $aatc-body-font;
}