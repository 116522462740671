@import 'variables';

@import 'browser-overrides';
@import 'bootstrap-loader';
@import 'bootstrap-overrides';
@import 'typography';

@import 'components/toast';
@import 'components/navbar';
@import 'components/sidenav';
@import 'components/date-picker';
@import 'components/data-grid';
@import 'components/modal';
@import 'components/autocomplete';
@import 'components/chip';

@import 'components/dashboard/data-display-widget';
@import 'components/dashboard/agent-list-widget';
@import 'components/remittance/remittance-submit'; 
@import 'components/remittance/remittance-report'; 

@import 'components/updates/update-card'; 
@import 'components/vehicle/vehicle-card'; 
@import 'components/vehicle/file-report'; 
@import 'components/vehicle/history-item'; 
@import 'components/vehicle/generate-expense-report'; 

@import 'components/withdrawal/view-withdrawal-modal'; 
@import 'components/withdrawal/create-withdrawal-modal'; 

@import 'components/disposal/create-disposal-modal'; 

@import 'components/cashbond/create-cashbond-modal'; 
@import 'components/motorpool/create-pd-modal'; 

@import 'pages/dashboard';
@import 'pages/remittance';
@import 'pages/updates';
@import 'pages/login';
@import 'pages/user-management';
@import 'pages/vehicle-diagnostic';
@import 'pages/withdrawal';
@import 'pages/motorpool';

@import 'pages/microscope';

.debug-layout {
  * {
    border: 1px solid hotpink !important;
  }
}