@import '../variables';
@import './borders';

@mixin widgetGeneric() {
  &__name {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 2px;
  }

  &__desc {
    font-size: 14px;
    color: $font-color-accent;
    margin-bottom: 0;
  }
  
  &__cat-toggle {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    padding-top: 1px;
    padding-bottom: 1px;
  
    background-color: white;
    color: $font-color-accent;
    border-color: $font-color-accent;
    box-shadow: none !important;
    font-size: 12px;
  
    margin-right: 8px;
    margin-bottom: 8px;
    // width: 68px;
    text-align: center;

    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
  
    @include anglo-border-radius(all, 16px);
  
    &.active {
      background-color: $font-color-accent;
      color: white;
    }
  }
}