@import '../variables';
@import '../mixins/borders';
@import '../mixins/card';

.microscope-remittance {


  &__controls {
    @include card();
    background-color: white;

    padding: 0.5rem;

    display: flex;
    flex-direction: row;

    &__options {
      box-sizing: border-box;
      
      padding: 1rem;
      min-height: 240px;
      width: 100%;

      &__head {
        font-size: 18px;
        // padding-bottom: 0.5rem;
        margin-bottom: 0;
        text-align: center;
        // border-bottom: 1px solid $divider-color;
      }

      &__label {
        font-size: 13px;
        margin-bottom: 4px;
      }

      &__data-selection {

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;

        &__btn {
          @include anglo-border-radius-smoothen(all);
          border: 1px solid $divider-color;

          background-color: white;
          color: $text-color;
          padding: 0.5rem 2rem;
          margin-bottom: 0.5rem;
          transition: all 0.3s ease;

          &.active {
            background-color: $lighter-text-color;
            color: white;
          }
        }
      }
    }

    &__params {
      box-sizing: border-box;
      border-top: 1px solid $divider-color;
      border-left: none;

      @include media-breakpoint-up(lg) {
        border-left: 1px solid $divider-color;
        border-top: none;
      }

      padding: 1rem;
      min-height: 240px;
      width: 100%;

      &__head {
        font-size: 18px;
        // padding-bottom: 0.5rem;
        margin-bottom: 0;
        text-align: center;
        // border-bottom: 1px solid $divider-color;
      }

      .param {
        &+.param {
          border-top: 1px solid $aatc-red;
        }
      }

      &__month {
        padding-bottom: 0.5rem;
        margin-bottom: 0.5rem;
      }

      &__supervisor {
        padding-bottom: 0.5rem;
        margin-bottom: 0.5rem;

        &__selected {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 0.5rem;

          .anglo-chip {
            margin-right: 4px;
            margin-bottom: 4px;
          }
        }
      }

      &__area {
        padding-bottom: 0.5rem;
        margin-bottom: 0.5rem;

        &__selected {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 0.5rem;

          .anglo-chip {
            margin-right: 4px;
            margin-bottom: 4px;
          }
        }
      }
    }
  }

  &__data {
    .chart {
      &__canvas {
        width: 100%;
      }
    }
  }

}