@import '../../variables';
@import '../../mixins/card';

.anglo-file-report-modal {

  &__expenses-list {

    &__add {
      font-size: 12px;
      border: 1px solid $divider-color;

      &:hover {
        background-color: $text-color;
        color: white;
      }
    }
    
  }
}