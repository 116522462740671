@import '../variables';

.anglo-chip {
  color: white;
  background-color: $light-gray-text-color;
  padding: 2px 1.5rem;
  min-width: 80px;
  font-size: 14px;
  border-radius: 12px;
  transition: all 0.3s ease;

  &:hover {
    background-color: $text-color;
  }

  &__icon-btn {
    background-color: transparent;
    outline: none;
    border: none;
    color: white;

    i {
      font-size: 14px;
    }
  }
}